import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

//import ReactDOM from 'react-dom/client';
//const root = ReactDOM.createRoot(document.getElementById('root'));
//root.render(
//  <React.StrictMode>
//    <App /> 
//  </React.StrictMode>
//);

// vs import ReactDOM from react-dom
ReactDOM.render(<App />, document.getElementById('root'));
