import React from 'react';
import Navbar from './components/Navbar';
import './App.css';
import Home from './components/pages/Home';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import SignUp from './components/pages/SignUp';

function App() {
  return (
    <>
      <Router>
        <Navbar />
        <Switch>
          <Route path='/' exact component={Home} />
          <Route path='/sign-up' component={SignUp} />
          <Route path='/contact' component={SignUp} /> 
          <Route path='/github' component={() => { 
              window.location.replace('https://github.com/Anzaetek'); 
              return null;
          }}/>
        </Switch>
      </Router>
    </>
  );
}

export default App;
