import React from 'react';
import '../App.css';
import { Button } from './Button';
import { ButtonServices } from './ButtonServices';
import './HeroSection.css';
import mainPic from '../assets/main_pic.png';
import { Link } from 'react-scroll';


function HeroSection() {
  return (
    <div className='hero-container' style={{ backgroundImage: `url(${mainPic})`, backgroundSize: 'cover' }}>
      
      <h1><span className='title-first-letter'>a</span>nzaetek</h1>
      <p className="italic-text2">Quantum Research</p>
      <div className='hero-btns'>
      <Link
          to='cards-section'
          spy={true}
          smooth={true}
          duration={500}
          offset={-80}
          className='btns btn--outline btn--large'
        >
          Get Started
        </Link>
        <Button
          className='btns'
          buttonStyle='btn--primary'
          buttonSize='btn--large'
          onClick={console.log('hey')}
        >
          GitHub 
        </Button>
      </div>
    </div>
  );
}

export default HeroSection;
