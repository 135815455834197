import React from 'react';
import './ContactPage.css';
import Footer from '../Footer';
import { FaGithub } from 'react-icons/fa';

export default function ContactPage() {
  return (
    <>
    <div className="contact-page">
      <h1 className="contact-title">Contact Us</h1>
      <p className="contact-text">For any inquiries, please email us at:</p>
      <a className="contact-text" href="mailto:contact@anzaetek.com">contact@anzaetek.com</a>
      <p></p>
      <p className="contact-text">You can also check out our GitHub:</p>
      <a className="contact-github" href="https://github.com/Anzaetek" target="_blank" rel="noopener noreferrer">
          <FaGithub size={32} />
          </a>
    </div>

    <Footer/>
    </>
  );
}
