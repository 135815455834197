import React from 'react';

import './CardQC.css';
import CardItem from './CardItem';
import { FaAtom } from 'react-icons/fa';
import partnershipLS from './img/partnership-Laplace-Sacados.png';
import graph_quantum from "./img/graph_save_ressource.png"

function Cards() {
  return (
    <div id='cards-section'>
    <div className="quantum-pag">

      <div className="quantum-containe">
        
        <div className="title2">{"< Quantum Optimization | Quantum ML >"}</div>
        
        <h2>Unlock the unparalleled potential of Anzaetek’s quantum machine learning and optimization solutions</h2>
        <p>
        {"We’re excited to provide you with exclusive access to one of the most advanced quantum & quantum-inspired computing solutions, placing you at the forefront of the rapidly evolving tech landscape."} </p>
        <p>
        At Anzaetek, we are aware of the power of optimization as the key to unlocking boundless potential in any business process.</p>
<p>Our next-generation quantum-inspired and (soon) quantum solutions leverage the full force of machine learning and advanced quantum algorithms to enable businesses to optimize operations in real-time and maximize profits.</p>
<p>We’re dedicated to pushing for you our quantum and quantum-inspired computing expertise, exploring new avenues to optimize our solutions and thus deliver the most competitive results of the market.</p>
       
        
        
        <p>
        If you’re ready to lead your business to new heights and redefine the future of your operations,
</p> <h2>Choose Anzaetek</h2> 
          
        <p>
          Get in touch with us today at <a className="contact-emai" href="mailto:info@anzaetek.com">info@anzaetek.com</a>
        </p>
        <FaAtom className="ico" />
      </div>
    </div>
    </div>
  );
}

export default Cards;
